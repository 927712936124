import React, { useState, useEffect } from "react"
import styled from "styled-components"

import "../homepage/layout.css"

import axios from "axios"
import BlogCards from "./BlogCards"

import { Section, Content } from "../core/LayoutComponents"

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const Page = () => {
  const [dataArray, setDataArray] = useState([])

  useEffect(() => {
    // const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
    // const apiCode = process.env.GATSBY_SPARKLE_API_CODE
    // const api = axios.create({
    // baseURL: "https://sparkleinternalfunctions-development.azurewebsites.net",
    // })

    // api.get(`https://sparkleinternalfunctions-development.azurewebsites.net/api/BlogArticles`)

    axios
      .get(`/data/articles.json`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // show all blog posts
        // setDataArray(response.data.data)

        // set data array to the latest 3 blog posts
        setDataArray(response.data.data.slice(0, 3))
      })
  }, [])

  // empty array should make it so that there is no infinite loop?

  const blogposts = dataArray.map(
    ({
      name: author,
      portalUrl: url,
      permalink,
      subject: title,
      summary,
      headerImageUrl: photo,
    }) => ({ author, url, title, summary, photo, permalink })
  )

  return (
    <Section>
      <Content>
        <div className="recent">
          <h1
            style={{
              paddingBlock: "1rem",
            }}
          >
            Recent Blog Posts
          </h1>
        </div>
        <BlogGrid>
          <BlogCards blogposts={blogposts} />
        </BlogGrid>
      </Content>
    </Section>
  )
}

export default Page
