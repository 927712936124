import React from "react"
import Seo from "../core/Seo"
import BlogLayout from "../blog/Page"
import { ThemeProvider } from "styled-components"

const BlogPage = () => {
    return (
      <ThemeProvider theme={{ colors: { accent: "#3790E2" } }}>
          <Seo title="Blog" includeZohoTracking />
          <BlogLayout />
      </ThemeProvider>
    )
  }
  
  export default BlogPage